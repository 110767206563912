@import '../../../../assets/styles/mixins';

.ScanQRModal {
  .ant-modal-content {
    @media (min-width: 1280px) {
      width: 50vw;
      max-width: 700px;
    }

    .ant-modal-body{
      @include breakpoint(mobile) {
        padding: 1rem;
      }
    }

    .campaign-info {
      display: flex;
      align-items: center;
      padding-bottom: 1.5rem;

      .campaign-logo {
        width: 90px;
        height: 90px;
        border: 1px solid var(--color-gray-light);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 2rem;
        @include breakpoint(mobile) {
          display: none;
        }
        .campaign-logo-img{
          border-radius: 50%;
          height: 90px;
          width: 90px;
          object-fit: cover;
        }
      }
      .campaign-data {
        color: var(--color-primary);
        flex-grow: 1;
        h2 {
          font-size: 26px;
          line-height: 50px;
          font-weight: 600;
          line-height: 1.2;
        }
        p {
          font-weight: 300;
          font-size: 12px;
          line-height: 15px;
          span{
            color: var(--action-color);
            display: block;
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }

    .qr-style {
      background-color: var(--color-white);
      -webkit-background-color: var(--color-white);
      text-align: center;
        h3 {
          color: var(--color-gray);
          font-weight: bold;
          font-size: 14px;
        }
        p {
          color: var(--color-primary);
          font-weight: bold;
          font-size: 20px;
        }
        .scan-panda-wrapper {
          @include breakpoint(mobile) {
            height: 200px;
         }
        .scan-panda-qr {
          @include breakpoint(mobile) {
            height: 200px;
          }
        }
      }
    }

    .scan-area {
      background-color: var(--color-white);
      border: 1px solid var(--color-gray-light);
      border-radius: 20px;
      padding: 1rem 1rem;
      padding-top: 40px;
      text-align: center;
      display: flex;
      flex-direction: column;
      
      h3 {
        color: var(--color-gray);
        font-weight: bold;
        font-size: 14px;
      }
      p {
        color: var(--color-primary);
        font-weight: bold;
        font-size: 20px;
      }
      .scan-panda-wrapper {
        height: 300px;
        display: flex;
        justify-content: center;
        @include breakpoint(mobile) {
          height: 200px;
        }
        .scan-panda {
          height: 100%;
        }
        .scan-panda-qr {
          height: 240px;
          position: absolute;
          transform: translateY(30px);
          @include breakpoint(mobile) {
            height: 120px;
          }
        }
        .small-qr-logo {
          height: 30px;
          position: absolute;
          transform: translateY(25px);
          opacity: 1;
        }
      }
    }
    .button-area {
      display: flex;
      justify-content: center;

      .button {
        width: 200px;
       
      }
      .button-share {
        padding: 0px 10px;
        border-radius: 0%;
        height: 48px;
       
      }

      .share-area {
        display: flex;
        flex-direction: 'row';
      }
      
      .link-text {
        padding-left: 5px;
        background-color: aqua;
        flex:1;
        width: 80%;
        
      }

    }
  }
  .download-link:hover{
    color: var(--color-white);
  }
}


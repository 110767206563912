.SideBarUser{
  text-align: center;
  padding: 1.5rem 0;

  .user-photo{
    height: 86px;
    width: 86px;
    border: 4px solid var(--secondary-color);
    background-color: var(--color-gray-light);
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
  }

  .user-upload-photo {
    height: 24px;
    width: 24px;
    border: 1px solid var(--primary-color);
    background-color: white;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 60px;
  }

  .user-photo-wrapper{
    position: relative;
  }

  .user-name{
    color: var(--secondary-color);
    display: block;
    font-size: 14px;
    margin-top: 12px;
  }
}


@import '../../../assets/styles/mixins';
.sidebar {

  &.ant-layout-sider {
    background: transparent !important;
    @include breakpoint(tablet) {
      position: fixed;
      z-index: 12;
      height: 100vh;
    }
    @include breakpoint(mobile) {
      position: fixed;
      z-index: 12;
      height: 100vh;
    }
  }
  .ant-layout-sider-children {
    background-color: var(--primary-color);
    color: var(--color-white);
    display: flex;
    flex-direction: column;
  }
}

.PayoutsChart {
  .Payouts-chart-label {
    font-size: 14px;
    margin-bottom: 18px;
  }
  .Payouts-Doughnut-wrapper {
    position: relative;
    .Payouts-Doughnut-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -40%);

      font-weight: bold;
      font-size: 26px;
      line-height: 62px;

      &.blue {
        color: #2f80ed;
      }
      &.green {
        color: #6fcf97;
      }
    }
    .Payouts-Doughnut-pattern {
      position: absolute;
      bottom: -12px;
      right: -4px;
    }
  }
}

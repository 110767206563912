.Horizontal-logo-container {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding-bottom: 10px;


  &.black {
    background-color: var(--color-black);
    .Logo-text {
      color: var(--color-white);
    }
  }
}

.Horizontal-logo-img {
  flex: 1;
  width: 100%;
  height: 100%;
    
  
}
@import '../../../assets/styles/mixins';
.Payouts {

  .ant-layout-content {
    background-color: #f9f9f9;

    .BasicContentLayout{
      padding-bottom: 60px;
      @include breakpoint(tablet) {
        padding-top: 45px;
      }
      @include breakpoint(mobile) {
        padding-top: 45px;
      }
    }

    .Payouts-cards-container {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;
      @include breakpoint(mobile) {
        flex-direction: column;
      }
    }

    .Payouts-chart-container {
      margin-top: 33px;
      .Payouts-chart {
        max-width: 136px;
        @include breakpoint(tablet) {
          margin-bottom: 3rem;
        }
        @include breakpoint(mobile) {
          margin-bottom: 2rem;
        }
      }
    }

    .Payouts-bottom {
      margin-top: 64px;
      .Payouts-price {
        font-family: var(--dmsans-font);
        font-weight: 500;
        color: var(--primary-color);
        font-size: 20px;
        line-height: 26px;
      }
      .Payouts-btn-transfer {
        margin-top: 31px;
      }
    }
  }
}

.ant-layout{
  &.dark{
    background-color: var(--primary-color);
    .dark-background{
      background-color: var(--primary-color);
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
    }
  }
} 
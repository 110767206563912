@import '../../../assets/styles/mixins';

.Campaigns {
  .ant-layout-content {
    background-color: #f9f9f9;
  }
  .Campaigns-header {
    display: flex;
    justify-content: space-between;
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
    }

    &.personal-content {
      @media (max-width: 650px) {
        flex-direction: column;
      }
      .campaign-mode-selector {
        @media (max-width: 650px) {
          text-align: center;
        }
      }
    }

    .title-container {
      flex:1
    }

    .campaign-mode-selector {
      @media (max-width: 650px) {
        margin-bottom: 15px;
        flex: 2;
        justify-content: center;
        display: flex;
      }

      .ant-radio-group {
        .ant-radio-button-wrapper {
          padding: 8px 22px;
          border: none;
          box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
          border-radius: 8px;
          margin: 0 5px;
          @include breakpoint(mobile) {
            display: block;
            margin: 0 auto;
            text-align: center;
            margin-bottom: .5rem;
          }
          &:last-child,
          &:first-child {
            border-radius: 8px;
          }
          &:not(:last-child)::before,
          &:not(:first-child)::before {
            content: none;
          }
        }
      }
    }

    .create-button-container {
      flex: 1;
      display: flex;
      justify-content: end;
    }
  }
  .Campaigns-body {
    margin-top: 40px;
    display: flex;
    justify-content: space-between; 
    align-items: stretch;
    flex-wrap: wrap;

    max-height: calc(100% - 24%);
    overflow-y: scroll;
    padding: 10px;
    @include breakpoint(mobile) {
      max-height: max-content;
      overflow-y: auto;
    }

    &.all-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 62px);
    }
    .Campaigns-no-campaigns {
      text-align: center;
      img {
        margin-bottom: 19px;
      }
      p {
        color: var(--color-gray-light);
        margin-bottom: 21px;
      }
      .Campaigns-search-button svg {
        margin-right: 15px;
      }
    }
  }
}

.skeleton-contanier {
  padding: 20px;
}

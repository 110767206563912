:root {
  --base-font: 'Mulish', sans-serif;
  --dmsans-font: 'DM Sans', sans-serif;
  --popins-font: 'Poppins', sans-serif;
  --primary-color: var(--color-black);
  --secondary-color: #ce4532;
  --action-color: #6fcf97;
  --color-yellow: #f2c94c;
  --color-gray: #828282;
  --color-disabled: #f2f2f2;
  --color-gray-light: #e0e0e0;
  --color-white: #ffffff;
  --color-black: #171717;
}

/* Colors */
$theme-colors: (
  primary: var(--primary-color),
  secondary: var(--secondary-color),
  action: var(--action-color),
  black: var(--color-black),
  white: var(--color-white),
  gray: var(--color-gray),
  gray-light: var(--color-gray-light),
);

/* Se generan clases a partir de los colores */
@each $name, $color in $theme-colors {
  .#{'' + $name}-bg {
    background-color: $color;
  }
  .#{'' + $name} {
    color: $color;
  }
}

@import '../../../assets/styles/mixins';
.dashboard {
  font-family: var(--base-font);
  font-size: 16px;
  overflow: hidden;

  &.ant-layout {
    background-color: var(--primary-color);
    height: 100vh;
    @include breakpoint(mobile) {
      height: auto;
    }
  }

  .menu-toogle-wrapper {
    background: #f9f9f9;
    position: relative;
    display: none;
    width: 100%;
    z-index: 10;
    .menu-toogle {
      width: 32px;
    }
    @include breakpoint(tablet) {
      display: block;
      position: fixed;
      z-index: 10;
      right: 0;
    }
    @include breakpoint(mobile) {
      display: block;
      position: fixed;
      z-index: 10;
      right: 0;
    }
    &.collapsed-false{
      @include breakpoint(tablet) {
        width: calc(100% - 200px);
      }
      @include breakpoint(mobile) {
        width: calc(100% - 200px);
      }
    }
  }
  .ant-layout-content {
    background-color: #e5e5e5;
    border-radius: 30px 0 0 30px;
    @include breakpoint(tablet) {
      border-radius: 0;
    }
    @include breakpoint(mobile) {
      border-radius: 0;
    }
  }
}

/* mixins & functions */
@function color($color) {
  @return map-get($theme-colors, $color);
}

@mixin breakpoint($point) {
  @if $point == desktop {
    @media (min-width: 1281px) { @content ; }
  }
  @else if $point == laptop_wide {
    @media (min-width: 1280px) and (max-width: 1440px) { @content ; }
  }
  @else if $point == laptop {
    @media (min-width: 1025px) and (max-width: 1280px) { @content ; }
  }
  @else if $point == tablet_down {
    @media (min-width: 310px) and (max-width: 768px) { @content ; }
  }
  @else if $point == tablet {
    @media (min-width: 768px) and (max-width: 1024px) { @content ; }
  }
  @else if $point == tablet_landscape {
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) { @content ; }
  }
  @else if $point == mobile {
    @media (min-width: 310px) and (max-width: 767px)  { @content ; }
  }
  @else if $point == mobile_landscape {
    @media (min-width: 310px) and (max-width: 767px) and (orientation: landscape)  { @content ; }
  }
}

@mixin ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
@import '../../../../../assets/styles/mixins';
.Payouts-card {
  border: 2px solid #f0f0f0;
  border-radius: 10px;
  padding: 32px 25px 63px 25px;
  display: inline-block;
  width: calc(50% - 26px);
  margin-bottom: 2rem;
  @include breakpoint(mobile) {
    width: 100%;
  }

  .Payouts-label {
    font-family: var(--popins-font);
    font-weight: 600;
  }
}

@import '../../../assets/styles/mixins';

.Campaigns {
  font-family: 'Mulish';
  font-style: normal;
  
  .Landing-campaigns-container {
    padding: 0% 10% 0%;
  }

  .Landing-campaigns-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
    }
    .Button-container {
     justify-content: center;
     align-items: center;
     display: flex;
     padding-top: 10px;
      padding-left: 20px;
      padding-right: 20px;
    }
    
  }

  .Button-container-auth {
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
   }
   .Container-auth {
    padding-left: 10px;
    padding-right: 10px;
   }

  .Campaigns-title {
    font-weight: 600;
    font-size: 26px;
    line-height: 62px;
   }

  .Campaigns-filters {
    display: flex;
    flex-direction: row;
   }

  .Landing-btn-create-campaign {
    height: 48px;
    padding: 0;
    width: 169px;
  }

  .Landing-form {
    margin-top: 10px;
  }
  .Landing-campaigns-body {
    margin-top: 6px;
    display: flex;
    justify-content: space-around; 
    align-items: stretch;
    flex-wrap: wrap;

    max-height: calc(100% - 24%);
    overflow-y: scroll;
    padding: 10px;
    @include breakpoint(mobile) {
      max-height: max-content;
      overflow-y: auto;
    }

    &.all-center {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 62px);
    }
    .Campaigns-no-campaigns-landing {
      text-align: center;
      margin-top: 100px;
      img {
        margin-bottom: 19px;
      }
      p {
        color: var(--color-gray-light);
        margin-bottom: 21px;
      }
    }
  }
}

.skeleton-contanier {
  padding: 20px;
}


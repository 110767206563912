@import '../../../assets/styles/mixins';


.BusinessWallet {
  .ant-layout-content {
    background-color: #f9f9f9;
  }


  .payment-methods-header {
    display: flex;
    justify-content: space-between;
    @media (max-width: 650px) {
      flex-direction: column;
      align-items: center;
    }

    &.personal-content {
      @media (max-width: 650px) {
        flex-direction: column;
      }
      .campaign-mode-selector {
        @media (max-width: 650px) {
          text-align: center;
        }
      }
    }

    .campaign-mode-selector {
      @media (max-width: 650px) {
        margin-bottom: 15px;
      }

      .ant-radio-group {
        .ant-radio-button-wrapper {
          padding: 8px 22px;
          border: none;
          box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07);
          border-radius: 8px;
          margin: 0 5px;
          @include breakpoint(mobile) {
            display: block;
            margin: 0 auto;
            text-align: center;
            margin-bottom: .5rem;
          }
          &:last-child,
          &:first-child {
            border-radius: 8px;
          }
          &:not(:last-child)::before,
          &:not(:first-child)::before {
            content: none;
          }
        }
      }
    }
  }


}

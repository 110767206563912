.PaymentForm{

  .payment-methods{
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    .ant-btn{
      border-radius: 4px;
      width: 48%;
    }
  }

  .fieldset-form{
    background-color: rgba(229, 229, 229, 0.4);
    border-bottom: 1px solid var(--color-gray-light);
    border-radius: 4px;
    margin-bottom: 1rem;

    .fieldset-header{
      color: #000000;
      font-size: 16px;
      line-height: 26px;
      text-align: left;
      border-bottom: 1px solid var(--color-gray-light);
      padding: 1rem  1.5rem;
    }

    .fieldset-inputs{
      padding: 1.5rem 1rem;
      padding-bottom: 0;

      .labeled-input{
        small{
          background-color: transparent;
          top: 4px;
          left: .2rem;
        }
        .ant-input{
          color: var(--color-black);
          padding-top: 1rem;
        }
      }

      .ant-form-item{
        margin-left: .5rem;
        margin-right: .5rem;
      }
    }
  }
}

.SignUp {
  .SignUp-container {
    max-width: 440px;
    text-align: center;
    margin: auto;
    padding-top: 40px;
    padding-bottom: 142px;
    @media (max-width: 600px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .ant-radio-group {
      margin-top: 14px;
      margin-bottom: 26px;
    }
  }
  .SignUp-subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 15px;
  }
}

@import './../../../assets/styles/mixins';

.CampaignCard {
  position: relative;
  display: flex;
  width: 46%;
  min-height: 257px;
  border-radius: 20px;
  box-shadow: 0px 2px 6px 0px #00000026;
  //padding: 14px 16px 18px 16px;
  margin-bottom: 40px;
  margin-right: 2.66%;
  // &:nth-child(4n) {
  //   margin-right: 0;
  // }

  @include breakpoint(laptop) {
    width: 46%;
  }
  @include breakpoint(tablet) {
    width: 98%;
  }
  @include breakpoint(mobile) {
    width: 98%;
  }

  .CampaignCard-side {
    background-color: #EFEFEF;
    padding: 16px 16px 34px 16px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .title {
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 25px;
      text-align: center;
    }

    .logo {
      display: flex;
      justify-content: center;
    }
  }

  .Campaign-content {
    padding: 14px 21px 18px 21px;
    flex:2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .info-business {
    margin-right: 15px;
    margin-top: 10px;
  }
  .info-business-last {
    margin-top: 10px;
  }

  .Campaign-logo {
    border-radius: 50%;
    height: 91px;
    width: 91px;
    border-width: 1px; 
    border-style: solid;
    border-color: var(--color-gray-light);
    &.show-qr{
      cursor: pointer;
    }
  }

  .Campaign-icon-more-container {
    cursor: pointer;
    display: inline-block;

    .Campaign-icon-more {
      margin-top: 18px;
    }
  }

  .Campaign-icon-pause {
    margin-right: 8.5px;
  }
  .Campaign-icon-edit {
    margin-top: 0.5rem;
  }

  .Campaign-title {
    font-size: 16px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 10px;
  }

  .Business-title{
    margin-top: 12px;
    line-height: 18px;
  }

  .Campaign-dates {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  }

  .Campaign-dates-business {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }

  .Campaign-values {
    margin-top: 10px;
  }
  .Campaign-label {
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 6px;
  }
  .Campaign-value {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }

  .Campaign-description {
    font-weight: 400;
    font-size: 10px;
    line-height: 13px;
  }
  .Campaign-description-container {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .Campaign-btn {
    // margin-top: 26px;
    padding: 2px 15px !important;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.secondary-bg {
      background-color: var(--secondary-color) !important;
    }
    &.secondary-bg:hover {
      border: 1px solid var(--secondary-color) !important;
    }
    &.white {
      color: var(--color-white) !important;
    }
  }

  .card-logo-col{
    display: flex;
    align-items: center;
    font-weight: bold;

    span {
      margin-left: 8px;
    }
  }
}

.CampaignCard-Dropdown {
  .ant-dropdown-menu {
    border-radius: 8px;
  }
  .ant-dropdown-menu-item {
    display: inline-block;
  }
  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover {
    background-color: transparent;
  }
}

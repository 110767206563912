.Campaign-content-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.floating-bin {
    display: flex;
    justify-content:flex-end;
    align-items: flex-end;
}

.Campaign-status {
    font-size: 14px;
    text-transform: capitalize;

    &.play {
      color: var(--action-color);
    }
    &.pause {
      color: var(--color-yellow);
    }
    &.expired, &.my-expired {
      color: var(--secondary-color);
    }    
}


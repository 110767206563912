.WOMCampaignForm{

  .makePublicRow {
    text-align: center;
    justify-content: center;
    align-items: center;
    h3{
      font-size: 14px;
      color: gray;
      padding-bottom: 25px;
    }
  }

  p{
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  .ant-form-item{
    margin-left: .5rem;
    margin-right: .5rem;

    h3{
      font-weight: 600;
      font-size: 15px;
      line-height: 20px;
    }
  }

  .ant-checkbox .ant-checkbox-inner {
    background-color: tran;
    border-color: black;
    border-radius: 25px;
    border-width: 1.5px;
    height: 25px;
    width: 25px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: transparent;
    border-color: black;
    border-radius: 25px;
    height: 25px;
    width: 25px;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    border-color: black;
    border-width: 1.5px;
    height: 10px;
  }
}

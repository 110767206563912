.StripeConnectModal{
  text-align: center;

  h2{
    font-weight: 600;
    font-size: 26px;
    line-height: 48px;
    margin: 0;
  }

  p{
    color: var(--color-gray);
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 2rem;
  }
}
.HomeBusiness {
  height: 100%;
  font-family: 'Mulish';
  font-style: normal;
  .ant-layout {
    height: 100%;
    .HomeBusiness-content {
      height: 100%;
      padding: 2rem 0;
      @media (max-width: 1000px) {
        height: auto;
      }
      .HomeBusiness-main-row {
        max-width: 1026px;
        margin: 0 auto;
        height: 100%;
        @media (max-width: 1024px) {
          padding: 0 2rem;
          padding-top: 2rem;

        }
        .ant-col{
          padding: 0 .7rem;

          span.accent{
            display: block;
            color: var(--secondary-color);
          }
        }
        .ant-btn.outline {
          margin-top: 36px;
          margin-bottom: 2.5rem;
        }
        .campaign-container {
          align-items: center;
          display: flex;
          flex-direction: column;
          width: 100%;
         

          .campaign-title {
            margin-bottom: 20px;
          }

          .skeleton-contanier {
            padding: 20px;
          }
          
        }
      }
    }
  }
}
@import './reset';
@import './variables';
@import './mixins';
@import './utilities';
@import './andtRewrites';

@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;600;800&family=DM+Sans:wght@500&family=Poppins:wght@600&display=swap');

#root {
  height: 100%;
}
html {
  scroll-behavior: smooth;
}

/* Change the appearance of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  background-color: rgba(0, 0, 0, 0);
  -webkit-border-radius: 80px;
}
::-webkit-scrollbar:hover {
  background-color: rgba(0, 0, 0, 0.09);
}
::-webkit-scrollbar-thumb:vertical {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 80px;
}
::-webkit-scrollbar-thumb:vertical:active {
  background: rgba(0, 0, 0, 0.61);
  -webkit-border-radius: 80px;
}

/* Layout */
.ant-layout-header {
  line-height: inherit;
}
.container {
  max-width: 1200px;
  margin: auto;
}

/* headings */
h1 {
  font-family: var(--base-font);
  font-style: normal;
  font-weight: 800;
  font-size: 50px;
  line-height: 53px;
}

h2 {
  font-family: var(--base-font);
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 62px;
}

/* Buttons */
button.ant-btn {
  border: 1px solid #e0e0e0;
  font-family: var(--base-font);
  font-size: 16px;
  border-radius: 55px;
  line-height: 26px;
  height: auto;
  padding: 13px 42px;

  &.ant-btn-primary {
    border-color: var(--primary-color);
  }
  &.ant-btn-sm {
    font-size: 14px;
    padding: 2px 47px;
  }
  &.action {
    background-color: var(--action-color);
    border-color: var(--action-color);
    color: var(--color-white);
  }
  &.disabled {
    background-color: var(--color-disabled);
    color: var(--color-gray);
    border-color: var(--color-disabled);
  }
  &.lock {
    cursor: not-allowed;
  }
  &.outline:hover {
    color: var(--primary-color);
  }
}

.hidden {
  display: none !important;
}

.bold {
  font-weight: 700;
}

/* Button groups */
.ant-radio-group {
  .ant-radio-button-wrapper {
    height: auto;
    padding: 10px 74px;
    border: 1px solid #e0e0e0;

    span {
      font-family: var(--base-font);
      font-size: 16px;
      line-height: 26px;
      color: var(--color-gray);
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }

    &.ant-radio-button-wrapper-checked {
      background-color: var(--primary-color);
      border: 1px solid var(--primary-color);

      span {
        color: var(--color-white);
      }
    }
  }
}

/* Inputs */
input.ant-input-number-input,
input.ant-input,
.ant-picker,
.ant-input-affix-wrapper {
  border-radius: 4px;
  color: #828282;
  font-family: var(--base-font);
  font-size: 14px;
  height: 48px;
  width: 100%;

  input.ant-input {
    height: 39px;
  }
}
.ant-input-number {
  width: 100%;
}
input.ant-checkbox-input {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
}
.ant-checkbox-checked .ant-checkbox-inner::after {
  border-width: 1px;
  width: 4px;
  height: 6px;
}
.ant-checkbox-inner::after {
  top: 45%;
  left: 35%;
}

.ant-input-search-large 
.ant-input-search-button {
  height: 48px !important;
}

.ant-select-selector {
  height: 50px !important;
  .ant-select-selection-search {
    height: 50px !important;
    .ant-select-selection-search-input {
      height: 50px !important;
    }
  }
}

/*Datepicker Panel*/
.ant-picker-dropdown {
  font-family: var(--base-font);
}

/* Modals*/
.ant-modal-content {
  border-radius: 20px;
  font-family: var(--base-font);

  .ant-modal-body {
    padding: 1rem 3rem;
    padding-top: 2.5rem;
  }
}

.ant-image-preview-img {
  background: #fff;
  border-radius: 12px;
}

.loading-block {
  text-align: center;
  position: relative;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  left: 0;
  right: 0;
  align-self: center;

  .loading-panda {
    width: 150px;
    display: block;
    margin: 0 auto;
  }
  .loading-spinner {
    width: 26px;
    display: block;
    margin: 0 auto;
  }
  h2 {
    line-height: 1.2;
  }
}

.ProfileForm{

  p{
    font-weight: 300;
    font-size: 12px;
    line-height: 15px;
  }
  .ant-form-item{
    margin-left: .5rem;
    margin-right: .5rem;

    h3{
      font-weight: 600;
      font-size: 16px;
      line-height: 34px;
    }
  }
}
.SignIn {
  .ant-layout-content {
    height: calc(100vh - 80px);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-height: 450px) {
      padding-top: 85px;
      align-items: flex-start;
    }

    .SignIn-form-container {
      text-align: center;
      transform: translateY(-80px);
      min-width: 420px;
      @media (max-width: 680px) {
        min-width: 340px;
      }

    }
  }
}

.SideBarHeader {
  padding-top: 2.5rem;
  text-align: center;

  .Logo-container {
    padding: 0;
    padding-bottom: 10px;
  }

  .user-type {
    color: var(--color-gray);
    display: block;
    font-size: 12px;
    font-weight: 700;
    line-height: 15px;
  }

  .separator-line {
    border: none;
    border-bottom: 1px solid #3c3c3c;
    margin-top: 1.5rem;
    width: 80%;
  }

  .Logo-text {
    text-align: initial;
  }
}

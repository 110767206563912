.Header {
  @media (max-width: 1200px) {
    padding-left: 1rem;
  }
  .Logo-container {
    margin-left: -25px;
  }
  
}
.Header-container {
  display: block;
  justify-content: center;
  align-items: center;
  max-width: 1200px;
  margin: auto;
}

.dark{
  .ant-layout-header, .ant-menu, button{
      background-color: #171717;
      color: #ffffff;
  }
}

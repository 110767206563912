@import '../../../assets/styles/mixins';
.NewWoMCampaign {
  .ant-layout-content {
    
    .card-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 2.5rem 0;
    }
    .wow-card {
      background-color: #ffffff;
      padding: 1.8rem 4rem 1rem;
      border-radius: 16px;
      width: 80%;
      max-width: 760px;
      min-width: 340px;
      position: relative;
      max-height: 90vh;
      overflow: auto;
      margin-top: 1rem;
      @include breakpoint(tablet) {
        padding: 1.5rem 1rem;
        /*max-height: 85vh;*/
      }
      @include breakpoint(mobile) {
        padding: 1.5rem 1rem;
        max-height: initial;
        /*max-height: 85vh;*/
      }

      .close-action{
        position: absolute;
        right: 5rem;
        top: 3rem;
        cursor: pointer;
        @include breakpoint(mobile) {
          right: 1rem;
        }
      }

      .wow-card-title {
        font-weight: 600;
        font-size: 26px;
        margin: 0 .5rem 1.3rem;
        padding-right: 2rem;
        line-height: 1.2;
      }
    }
  }
}

.Logo-container {
  display: inline-flex;
  

  .Logo-img {
    height: 50px;
  }

  &.black {
    background-color: var(--color-black);
    .Logo-text {
      color: var(--color-white);
    }
  }
}
.MenuHeader {
  &.ant-menu-horizontal {
    border-bottom: none;
    justify-content: flex-end;
    align-items: center;

    .ant-menu-item {
      padding: 0 19px;

      &:hover::after {
        bottom: -4px;
        left: 6px;
        width: 90%;
      }

      .ant-menu-title-content {
        font-family: var(--base-font);
        font-size: 14px;
      }

      button.ant-btn.ant-btn-sm {
        padding: 10px 30px;
        line-height: 18px;
      }
    }

    .ant-menu-item:hover::after,
    .ant-menu-submenu:hover::after,
    .ant-menu-item-active::after,
    .ant-menu-submenu-active::after,
    .ant-menu-item-open::after,
    .ant-menu-submenu-open::after,
    .ant-menu-item-selected::after,
    .ant-menu-submenu-selected::after {
      content: none;
    }
  }
}

.ant-layout.dark {
  .MenuHeader {
    .ant-menu-item:hover {
      color: var(--secondary-color);
    }
    .ant-menu-item-selected {
      color: var(--color-white);
    }
  }
}

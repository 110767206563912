@import '../../../../../assets/styles/mixins';
.SideBarBottom {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 5rem;
  padding: 0 2rem;
  box-sizing: border-box;

  .SideBarBottom-logout-icon {
    display: inline-block;
    cursor: pointer;
  }
  @include breakpoint(mobile) {
    height: 8rem;
  }
  @include breakpoint(tablet) {
    height: 8rem;
  }
}

.SideBarMenu {
  flex-grow: 1;

  .ant-menu {
    border: none;

    .ant-menu-item {
      padding-left: 45px !important;
      width: 100% !important;

      .ant-menu-title-content {
        color: var(--color-white);
        font-family: var(--base-font);
        a{
          color: var(--color-white);
        }
      }

      &.ant-menu-item-selected {
        background-color: transparent;
      }
    }
  }

  .skeleton-container {
    padding: 0 45px;
  }
}

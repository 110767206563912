@import '../../../assets/styles/mixins';
.Profile {
  .ant-layout-content {
    .card-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      padding: 2.5rem 0;
    }

    .wow-card {
      background-color: #ffffff;
      padding: 2.5rem 4rem;
      border-radius: 16px;
      width: 80%;
      max-width: 760px;
      min-width: 340px;
      position: relative;
      max-height: 85vh;
      overflow: auto;
      margin-top: 1rem;
      @include breakpoint(mobile) {
        padding: 1.5rem 1.5rem;
        max-height: initial;
      }

      .close-action{
        position: absolute;
        right: 5rem;
        top: 3rem;
        cursor: pointer;
        @include breakpoint(mobile) {
          right: 1rem;
        }
      }

      .wow-card-title {
        font-weight: 600;
        font-size: 26px;
        margin: 0 .5rem;
        margin-bottom: 2rem;
        padding-right: 2rem;
      }

      .ant-col{
        @include breakpoint(mobile) {
          width: 100%;
        }
      }
    }
  }
}

label.labeled-input {
  display: block;
  font-family: var(--base-font);
  margin-top: .6rem;
  position: relative;

  small {
    background-color: #ffffff;
    color: #828282;
    font-size: 12px;
    left: .5rem;
    position: absolute;
    padding: 0 .5rem;
    top: -6px;
    z-index: 1;
    
  }

}
@import '../../../assets/styles/mixins';
.BasicContentLayout {
  margin: 0 auto;
  width: 100%;
  padding: 0 5%;
  padding-top: 61px;
  height: calc(100% - 5px);
  overflow: auto;
  @include breakpoint(mobile) {
    height: auto;
  }
}
